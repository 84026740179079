import React from 'react';

export default function NoEventsFound() {
	{
		/* TODO: Edit this text */
	}
	return (
		<p>
			Geen evenementen gevonden - kijk op{' '}
			<a
				href='https://www.visitzandvoort.nl/uitagenda/'
				target='_blank'
				rel='noreferrer'>
				Visit Veluwe
			</a>
		</p>
	);
}
