import { ExtendedItemType } from "@/models/ItemModel";
import { CustomCategorizationOntology, selectedCategorizationOntology } from "./select-categorization-ontology";
import { EVENT_CATEGORIES, LOCATION_CATEGORIES, ROUTE_CATEGORIES } from "./categories";
const isParentCnetID = (str: string): boolean => {
	const regex = /^\d\.\d$/;
	return regex.test(str);
};

const categorizationOntology = await selectedCategorizationOntology();


const getEventCatIdByCategory = (category: string): string => {
	if (!category) return '';
	const eventCategory = EVENT_CATEGORIES.find((cat) => {
		if (category === 'Evenementen') category = 'Evenement';
		if (cat.categorization.toLowerCase() === category.toLowerCase()) {
			return cat;
		}
		if (!cat.child) return false;
		// console.log(cat.child);
		const childCategory = cat.child.find((child: any) => child.categorization.toLowerCase() === category.toLowerCase());
		if (childCategory) {
			return childCategory;
		}

		return false;
	});

	console.log('eventCategory', eventCategory);

	if (category === 'Evenement') {
		return eventCategory?.cnetID ? eventCategory?.cnetID : '';
	}

	const childCategory = eventCategory?.child.find((child: any) => child.categorization.toLowerCase() === category.toLowerCase())?.cnetID;

	return childCategory ? childCategory : '';
}

const getLocationCatIdByCategory = (category: string): string => {
	const locationCategory = LOCATION_CATEGORIES.find((cat) => {
		if (!cat.child) return false;
		const childCategory = cat.child.find((child: any) => child.categorization.toLowerCase() === category.toLowerCase());
		if (childCategory) {
			return childCategory;
		}

		return false;
	});

	const childCategory = locationCategory?.child.find((child: any) => child.categorization.toLowerCase() === category.toLowerCase())?.cnetID;

	return childCategory ? childCategory : '';
}

const getRouteCatIdByCategory = (category: string): string => {
	const routeCategory = ROUTE_CATEGORIES.find((cat) => {
		if (!cat.child) return false;
		const childCategory = cat.child.find((child: any) => child.categorization.toLowerCase() === category.toLowerCase());
		if (childCategory) {
			return childCategory;
		}

		return false;
	});

	const childCategory = routeCategory?.child.find((child: any) => child.categorization.toLowerCase() === category.toLowerCase())?.cnetID;

	return childCategory ? childCategory : '';
}

const findEventCategory = (categoryId: string) => {
	if (!categoryId) return '';

	const eventCategories = (categorizationOntology as CustomCategorizationOntology).filter((cat) => cat.entityType === "EVENEMENT");

	if (isParentCnetID(categoryId)) {
		const category = eventCategories.find((cat) => cat.cnetID === categoryId);
		return category?.categorization.toLowerCase() ? category?.categorization.toLowerCase() : '';
	}

	const category = eventCategories.find((cat) => {
		if (!cat.child) return false;
		return cat.child.find((child: any) => {
			return child.cnetID === categoryId
		});
	});

	const childCategory = category?.child.find((child: any) => child.cnetID === categoryId);

	return childCategory?.categorization.toLowerCase() ? childCategory?.categorization.toLowerCase() : '';
}

const findLocationCategory = (categoryId: string) => {
	if (!categoryId) return '';

	const locationCategories = (categorizationOntology as CustomCategorizationOntology).filter((cat) => cat.entityType === "LOCATIE");

	if (isParentCnetID(categoryId)) {
		const category = locationCategories.find((cat) => cat.cnetID === categoryId);

		return category?.categorization.toLowerCase() ? category?.categorization.toLowerCase() : '';
	}

	const category = locationCategories.find((cat: any) => {
		if (!cat.child) return false;
		return cat.child.find((child: any) => {
			return child.cnetID === categoryId
		});
	});

	const childCategory = category?.child.find((child: any) => child.cnetID === categoryId);

	return childCategory?.categorization.toLowerCase() ? childCategory?.categorization.toLowerCase() : '';
}

const findRouteCategory = (routeCategory: ExtendedItemType) => {
	if (!routeCategory || !routeCategory.catid) return '';

	const routeCategories = (categorizationOntology as CustomCategorizationOntology).filter((cat: any) => cat.entityType === "ROUTE");

	let categoryId = routeCategory.catid;

	let category = routeCategories.find((cat) => {
		return cat.child.find((child: any) => {
			return child.cnetID === categoryId
		});
	});

	let categoryString = category?.categorization.toLowerCase();


	if (!categoryString) {
		for (let i = 0; i < routeCategory.categoryTranslations.length; i++) {
			if (routeCategory.categoryTranslations[i].label.toLowerCase() === "menroute") {
				categoryString = 'paardrijden'; // temporary because there's categoryId that doesn't match with categorization ontology
			}
		}
	}

	return categoryString;
}

export { findEventCategory, findLocationCategory, findRouteCategory, getEventCatIdByCategory, getLocationCatIdByCategory, getRouteCatIdByCategory };