import { LocationModel } from '@/models/ItemModel';
import './LocationAsImage.module.scss';

const LocationAsImage = (props: { location: LocationModel }) => {
	const location = props.location;

	return (
		<div className='overflow-hidden location-img-container'>
			<a href={location.url} target='_blank' rel='noreferrer'>
				<div className='relative'>
					<img
						src={location.image.url}
						alt={location.image.alt}
						className='object-cover w-full h-[50vh] rounded-xl'
					/>
					{/* <div className='absolute top-2 right-2'>
						<button className='p-2 bg-white rounded-full'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='w-6 h-6 text-gray-700'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M5 13l4 4L19 7'
								/>
							</svg>
						</button>
					</div> */}
				</div>
				<div className='pt-2'>
					<h3 className='text-lg font-semibold'>{location.title}</h3>
					<p className='text-sm text-gray-600'>{location.address}</p>
					{/* <div className='mt-2 text-xs text-gray-500'>
						<span className='px-2 py-1 bg-gray-200 rounded'>
							{location.category}
						</span>
					</div> */}
				</div>
				{location.category && (
					<div
						className='flex gap-1 px-4 pt-2 pb-1 mt-2 text-center text-gray-700 w-fit rounded-2xl'
						style={{ backgroundColor: 'rgba(0, 0, 0, .08)' }}>
						<p>{location.category}</p>
					</div>
				)}
			</a>
		</div>
	);
};

export default LocationAsImage;
