// Change hex color into RGB
// Adapted from: https://blog.logrocket.com/applying-dynamic-styles-tailwind-css/
export const getRGBColor = (hex: string): string => {
  // Remove the hash at the start if it's there
  const cleanHex = hex.startsWith('#') ? hex.slice(1) : hex;
  // Parse the r, g, b values
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);
  // Return the RGB string
  return `${r}, ${g}, ${b}`;
};
