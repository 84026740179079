import { Spinner } from './Spinner';
import { MoreInfo } from './MoreInfo';
import React, { useEffect, useState } from 'react';
import { EventModel, LocationModel, RouteModel } from '@/models/ItemModel';
import { retrieveEvents } from '../lib/events';
import { retrieveLocations } from '../lib/locations';
import { retrieveRoutes } from '../lib/routes';
import { useTranslation } from 'react-i18next';
import { WidgetEntityType } from '@/models/WidgetEntityType';
import { FooterData } from '@/models/FooterData';
import ItemsSwiper from './Swiper';
import { widgetConfig } from '../config/widgetConfig';

export const Widget = (props: {
  filterOnType: WidgetEntityType;
  lang: string;
  city?: string;
  category?: string;
}) => {
  const [events, setEvents] = useState<EventModel[]>([]);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [routes, setRoutes] = useState<RouteModel[]>([]);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t, ready } = useTranslation();
  const eventsAreLoaded: boolean = !isLoading && events.length > 0;
  const locationsAreLoaded: boolean = !isLoading && locations.length > 0;
  const routesAreLoaded: boolean = !isLoading && routes.length > 0;

  useEffect(() => {
    setIsLoading(true);
    if (props.filterOnType === 'event') {
      retrieveEvents(
        props.filterOnType,
        t,
        props.lang,
        props.city,
        props.category
      )
        .then((eventResults) => {
          setEvents(eventResults);
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (props.filterOnType === 'location') {
      retrieveLocations(
        props.filterOnType,
        t,
        props.lang,
        props.city,
        props.category
      )
        .then((locationResults) => {
          setLocations(locationResults);
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (props.filterOnType === 'route') {
      retrieveRoutes(
        props.filterOnType,
        t,
        props.lang,
        props.city,
        props.category
      )
        .then((routeResults) => {
          setRoutes(routeResults);
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [props.filterOnType, props.lang, props.city]);

  const translatedLink = () => {
    switch (props.filterOnType) {
      case 'event':
        return `${t(`veluwe:callToAction.event.url`)}?utm=veluwewidget`;
      case 'location':
        return `${t(`veluwe:callToAction.location.url`)}?utm=veluwewidget`;
      case 'route':
        return `${t(`veluwe:callToAction.route.url`)}?utm=veluwewidget`;
      default:
        return '';
    }
  };
  const translatedTitle = () => {
    switch (props.filterOnType) {
      case 'event':
        return t(`veluwe:event.title`);
      case 'location':
        return t(`veluwe:location.title`);
      case 'route':
        return t(`veluwe:route.title`);
      default:
        return '';
    }
  };

  const footerData: FooterData = {
    text: t(`veluwe:callToAction.text`),
    linkUrl: translatedLink(),
    logoUrl: widgetConfig.logoUrl,
  };

  return (
    <div className={'h-screen flex flex-col md:justify-center pt-1 pb-2 px-2'}>
      <h1
        className={
          'font-bold text-sm xs:text-lg md:text-2xl text-gray-900 mb-1'
        }>
        {translatedTitle()}
      </h1>

      {isLoading && !ready && <Spinner />}
      {error !== '' && <p className={'text-red-800'}>{error}</p>}

      {/* Side-by-side */}
      <div className={'hidden xxs:grid grid-cols-12 gap-4 h-[90%]'}>
        {eventsAreLoaded && (
          <div className={'col-span-12 md:col-span-12 lg:col-span-12'}>
            <ItemsSwiper
              items={events}
              slidesPerView={Math.min(events.length, 3)}
              className={'hidden lg:block'}
            />
            <ItemsSwiper
              items={events}
              slidesPerView={Math.min(events.length, 2)}
              className={'hidden md:block lg:hidden'}
            />
            <ItemsSwiper
              items={events}
              slidesPerView={Math.min(events.length, 1)}
              className={'md:hidden'}
            />
          </div>
        )}
        {locationsAreLoaded && (
          <div className={'col-span-12 md:col-span-12 lg:col-span-12'}>
            <ItemsSwiper
              items={locations}
              slidesPerView={Math.min(locations.length, 3)}
              className={'hidden lg:block'}
            />
            <ItemsSwiper
              items={locations}
              slidesPerView={Math.min(locations.length, 2)}
              className={'hidden md:block lg:hidden'}
            />
            <ItemsSwiper
              items={locations}
              slidesPerView={Math.min(locations.length, 1)}
              className={'md:hidden'}
            />
          </div>
        )}
        {routesAreLoaded && (
          <div className={'col-span-12 md:col-span-12 lg:col-span-12'}>
            <ItemsSwiper
              items={routes}
              slidesPerView={Math.min(routes.length, 3)}
              className={'hidden lg:block'}
            />
            <ItemsSwiper
              items={routes}
              slidesPerView={Math.min(routes.length, 2)}
              className={'hidden md:block lg:hidden'}
            />
            <ItemsSwiper
              items={routes}
              slidesPerView={Math.min(routes.length, 1)}
              className={'md:hidden'}
            />
          </div>
        )}
      </div>

      {/* Row-by-row */}
      <div className={'flex flex-col xxs:hidden h-full'}>
        {eventsAreLoaded && <ItemsSwiper items={events} slidesPerView={1} />}
        {locationsAreLoaded && (
          <ItemsSwiper items={locations} slidesPerView={1} />
        )}
        {routesAreLoaded && <ItemsSwiper items={routes} slidesPerView={1} />}
      </div>

      <MoreInfo className={'h-20 lg:text-lg text-sm'} footerData={footerData} />
    </div>
  );
};
