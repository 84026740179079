import veluweNL from '../locales/nl/veluwe.json';
import veluweEN from '../locales/en/veluwe.json';
import veluweDE from '../locales/de/veluwe.json';
import veluweLogo from "../assets/veluew-logo.svg";

const MAX_ITEM_COUNT = 10;

export interface WidgetTypeInfo {
	keywords: string;
}
export interface WidgetTypes {
	[type: string]: WidgetTypeInfo;
}

interface BaseParams {
	format: string;
	size: number;
	sortField?: string;
	sortOrder?: string;
	userorganization: string;
	wfstatus: string;
	keywords?: string;
}
interface WidgetConfig {
	logoUrl: string;
	baseParams: {
		event: BaseParams;
		location: BaseParams;
		route?: BaseParams;
	};
	types: WidgetTypes;
	translations: { lang: string; file: any }[];
	theme: {
		primaryColor: string;
		secondaryColor: string;
	};
}

export const widgetConfig: WidgetConfig = {
	logoUrl: veluweLogo,
	baseParams: {
		event: {
			format: 'json',
			size: MAX_ITEM_COUNT,
			sortField: 'eventStart',
			sortOrder: 'ASC',
			userorganization: 'veluwe',
			wfstatus: 'approved',
		},
		location: {
			format: 'json',
			size: MAX_ITEM_COUNT,
			userorganization: 'veluwe',
			wfstatus: 'approved',
		},
		route: {
			format: 'json',
			size: MAX_ITEM_COUNT,
			userorganization: 'veluwe',
			wfstatus: 'approved',
		}
	},
	types: {
		event: {
			keywords: 'uitagenda',
		},
	},
	translations: [
		{ lang: 'nl', file: veluweNL },
		{ lang: 'en', file: veluweEN },
		{ lang: 'de', file: veluweDE },
	],
	theme: {
		primaryColor: '#021353',
		secondaryColor: '#1270C5',
	},
};

