import { selectedCategorizationOntology } from "./select-categorization-ontology";
import { GenericCategorizationOntology } from "./select-categorization-ontology";
import { CustomCategorizationOntology } from "./select-categorization-ontology";
const categorizationOntology = await selectedCategorizationOntology();

export const LOCATION_CATEGORIES = (categorizationOntology as CustomCategorizationOntology).filter((cat) => cat.entityType === "LOCATIE");

export const EVENT_CATEGORIES = (categorizationOntology as CustomCategorizationOntology).filter((cat) => cat.entityType === "EVENEMENT");

export const ROUTE_CATEGORIES = (categorizationOntology as CustomCategorizationOntology).filter((cat) => cat.entityType === "ROUTE");

