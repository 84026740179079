import { RouteModel } from '@/models/ItemModel';
import './RouteAsImage.module.scss';
import bicycle from '../../../assets/icons/bicycle.svg';
import horse from '../../../assets/icons/horse.svg';
import walking from '../../../assets/icons/walk.svg';

const RouteAsImage = (props: { route: RouteModel }) => {
	const route = props.route;

	const routeIcon = () => {
		switch (route.category) {
			case 'fietsen':
				return <img src={bicycle} alt='fietsen' className='h-5' />;
			case 'wandelen':
				return <img src={walking} alt='wandelen' className='h-5' />;
			case 'paardrijden':
				return <img src={horse} alt='paardrijden' className='h-5' />;
		}
	};

	return (
		<div className='overflow-hidden route-img-container'>
			<a href={route.url} target='_blank' rel='noreferrer' className='h-full'>
				<div className='relative'>
					<img
						src={route.image.url}
						alt={route.image.alt}
						className='object-cover w-full h-[50vh] rounded-xl'
					/>
					{route.distance && (
						<div
							className='flex items-center gap-2 px-4 py-1 mt-4 text-center text-gray-700 w-fit rounded-2xl'
							style={{ backgroundColor: 'rgba(0, 0, 0, .08)' }}>
							{routeIcon()}
							<p className='pt-1'>{route.distance} km</p>
						</div>
					)}
				</div>
				<div className='pt-2'>
					<h3 className='text-lg font-semibold'>{route.title}</h3>
					<p className='text-sm text-gray-700'>{route.description}</p>
				</div>
			</a>
		</div>
	);
};

export default RouteAsImage;
