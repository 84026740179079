import { categorizationOntology } from "@feed-factory/feed-factory.data.categorization-ontology";
import { widgetConfig } from "../../config/widgetConfig";
import axios, { AxiosInstance } from "axios";

export type GenericCategorizationOntology = {
  categorizations: ({
    categorizationId: string;
    categorization: string;
    cnetID: string;
    entityType: string;
    child: ({
      categorizationId: string;
      categorization: string;
      cnetID: string;
      deprecated?: undefined;
    } | {
      categorizationId: string;
      categorization: string;
      cnetID: string;
      deprecated: boolean;
    })[];
    deprecated?: undefined;
  } | {
    categorizationId: string;
    categorization: string;
    cnetID: string;
    entityType: string;
    deprecated: boolean;
    child: {
      categorizationId: string;
      categorization: string;
      cnetID: string;
    }[];
  } | {
    categorizationId: string;
    categorization: string;
    cnetID: string;
    entityType: string;
    deprecated: boolean;
    child?: undefined;
  } | {
    categorizationId: string;
    categorization: string;
    cnetID: string;
    entityType: string;
    deprecated: boolean;
    child: {
      categorizationId: string;
      categorization: string;
      deprecated: boolean;
      cnetID: string;
    }[];
  } | {
    categorizationId: string;
    categorization: string;
    cnetID: string;
    deprecated: boolean;
    child: {
      categorizationId: string;
      categorization: string;
      cnetID: string;
    }[];
    entityType?: undefined;
  })[];
};

export type CustomCategorizationOntology = {
  categorizationId: string;
  categorization: string;
  cnetID: string;
  entityType: string;
  child: ({
    categorizationId: string;
    categorization: string;
    cnetID: string;
    deprecated?: undefined;
  } | {
    categorizationId: string;
    categorization: string;
    cnetID: string;
    deprecated: boolean;
  })[];
  deprecated?: undefined;
}[];

const myAxios = axios.create({
  baseURL: "https://app.thefeedfactory.nl/api",
});

const feedFactoryAxios: AxiosInstance = myAxios;

feedFactoryAxios.defaults.baseURL = 'https://app.thefeedfactory.nl/api';
feedFactoryAxios.defaults.headers.common[
  "Authorization"
] = `Bearer 6ac83e1d-f100-44b6-a6cf-f81c7cbeed75`;

export const selectedCategorizationOntology: () => Promise<GenericCategorizationOntology | CustomCategorizationOntology> = async () => {
  if (widgetConfig.baseParams.location.userorganization) {
    const organisation = await feedFactoryAxios.get("accounts", {
      params: {
        search: widgetConfig.baseParams.location.userorganization,
      },
    });

    return organisation.data.results.length > 0 ? JSON.parse(organisation.data.results[0].metaData.categorizationOntology) as CustomCategorizationOntology : categorizationOntology;
  }
  return categorizationOntology;
};