import { EventModel } from '@/models/ItemModel';
import './EventAsImage.scss';

// TODO: Add a green label with text '
const EventAsImage = (props: { event: EventModel }) => {
	const event = props.event;

	return (
		<div
			className={
				'relative h-[80vh] overflow-hidden event-img-container rounded-2xl flex flex-col justify-between'
			}>
			<a
				href={event.url}
				target='_blank'
				rel='noreferrer'
				className='flex flex-col h-full'>
				<img
					src={event.image.url}
					alt={event.image.alt}
					className={
						'w-full h-full object-cover block transition-transform ease-in-out duration-500'
					}
				/>
				<div
					className={
						'absolute top-0 left-0 bottom-0 right-0 w-full h-full object-cover ' +
						'block bg-gradient-to-t via-transparent opacity-60'
					}
					style={{
						// @ts-ignore
						'--tw-gradient-stops':
							'var(--tw-gradient-from, rgba(0, 0, 0, 0.7)), 30%, transparent, var(--tw-gradient-to, rgb(0 0 0 / 0))',
					}}
				/>{' '}
				<div className='absolute bottom-0 w-full p-5 text-white'>
					<p className={'text-xs sm:text-base'}>{event.date}</p>
					<p className={'text-2xl font-glypha font-bold'}>{event.title}</p>
					<p className={'text-base'}>{event.address}</p>
				</div>
			</a>
		</div>
	);
};

export default EventAsImage;
