import { feedFactoryAxios } from "@feed-factory/feed-factory.helpers";
import { ExternalRouteItemModel } from "@feed-factory/feed-factory.models.ndtrc/dist/Ndtrc";
import { CustomCategorizationOntology, selectedCategorizationOntology } from "./common/select-categorization-ontology";
import { RouteModel } from "@/models/ItemModel";
import { ExtUrlDetails } from "@feed-factory/feed-factory.models.ndtrc";
import { WidgetEntityType } from "@/models/WidgetEntityType";
import { widgetConfig } from "../config/widgetConfig";
import { addressForRoute } from "../helpers/addressBuilder";
import { ExtendedExternalRouteItemModel, ExtendedItemType } from "@/models/ItemModel";
import slugify from "slugify";
import { findRouteCategory, getRouteCatIdByCategory } from "./common";
import { get } from "http";


const getTitleInLang = (
  route: ExternalRouteItemModel,
  lang: string | null
): string => {
  const langItem = Array.isArray(route.trcItemDetails) ? route.trcItemDetails.find((item) => item.lang === lang) : null;
  if (langItem) return langItem.title;
  // If specified language not found, return primary language of item
  return route.trcItemDetails && route.trcItemDetails.length > 0 ? route.trcItemDetails[0].title : "";
};

export const retrieveRoutes = async (
  filterOnType: WidgetEntityType = "route",
  t: any, // translation function
  lang: string | null = "nl",
  city?: string,
  category?: string
): Promise<RouteModel[]> => {
  let queryParams = {};
  const baseQueryParams = widgetConfig.baseParams.route;
  queryParams = baseQueryParams

  // Get additional filter params based on type
  if (category) {
    const categorySplit = category.split(",");
    if (categorySplit.length > 1) {
      const categoryIDs = categorySplit.map((cat) => getRouteCatIdByCategory(cat));
      queryParams = { ...queryParams, types: categoryIDs.join(",") };
    } else {
      queryParams = { ...queryParams, types: getRouteCatIdByCategory(category) };
    }
  }

  if (city) {
    queryParams = { ...queryParams, city: city };
  }

  const routes = await feedFactoryAxios.get("routes", {
    params: queryParams,
  });

  if (!routes?.data?.results) {
    return Promise.reject("Could not retrieve routes data...");
  }

  if (routes.data.results.length === 0) {
    alert("No routes found");
    return Promise.resolve([]);
  }

  const parsedRoutes: Promise<RouteModel[]> = parseRoutes(
    routes.data.results,
    filterOnType,
    t,
    lang
  );
  return Promise.resolve(parsedRoutes);
};

const parseRoutes = async (
  routes: ExtendedExternalRouteItemModel[],
  filterOnType: WidgetEntityType,
  t: any, // translation function
  lang: string | null
): Promise<RouteModel[]> => {

  let filteredRoutes = routes.filter((route) => {
    const hasImage = route.files && route.files.length > 0;
    const hasTitle = route.trcItemDetails && route.trcItemDetails.length > 0;
    return hasTitle && hasImage;
  });

  let parsedRoutes = await Promise.all(filteredRoutes.map(async (route) => {
    let mainImage = route.files?.[0];
    let mainImages = route.files?.filter((file) => file.main) ?? [];
    if (mainImages && mainImages.length > 0) {
      mainImage = mainImages[0];
    }
    const title = getTitleInLang(route, lang);
    // Set default urls in case none in item

    const categorizationOntology = await selectedCategorizationOntology();
    const routeCategories = (categorizationOntology as CustomCategorizationOntology).filter((cat) => cat.entityType === "ROUTE");

    // Find the category of the route
    const routeCategory = route.trcItemCategories.types[0] as ExtendedItemType;

    let categoryString = "";

    // Skip if no category is found
    if (routeCategory) {

      let categoryId = routeCategory.catid;

      let category = routeCategories.find((cat) => {
        return cat.child.find((child) => {
          return child.cnetID === categoryId
        });
      });


      categoryString = category?.categorization.toLowerCase();


      if (!categoryString) {
        for (let i = 0; i < routeCategory.categoryTranslations.length; i++) {
          if (routeCategory.categoryTranslations[i].label.toLowerCase() === "menroute") {
            categoryString = 'paardrijden'; // temporary because there's categoryId that doesn't match with categorization ontology
          }
        }
      }
    }

    slugify.extend({ '&': '' })
    let visitUrl = `${t("veluwe:callToAction.route.url")}/${categoryString}/${slugify(title, { lower: true })}`;
    // Fill with language-specific URL
    // if (
    //   route.contactinfo !== undefined &&
    //   route.contactinfo.urls !== undefined &&
    //   route.contactinfo.urls.length > 0
    // ) {
    //   let myUrl: ExtUrlDetails | undefined = route.contactinfo.urls.find(
    //     (url) => url.targetLanguage === lang
    //   );
    //   if (myUrl) {
    //     visitUrl = myUrl.url;
    //   } else {
    //     visitUrl = route.contactinfo.urls[0].url;
    //   }
    // }

    // console.log('visitURL', visitUrl);
    // find route description in specified language
    let description = route.trcItemDetails?.find((item) => item.lang === lang)?.shortdescription || "";

    return {
      id: route.id as string,
      title: title,
      type: filterOnType,
      address: route.location ? addressForRoute(route.location.address) : "",
      url: visitUrl + "?utm_source=partner&utm_medium=widget&utm_id=veluwe&utm_content=route",
      distance: parseFloat(route.routeInfo.distanceInKilometers.toFixed(2)), description: description,
      category: categoryString,
      image: {
        url: mainImage?.hlink || "", // Use empty string as default value if mainImage?.hlink is undefined
        alt: title,
      },
    };
  }));

  return parsedRoutes;
};