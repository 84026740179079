import { feedFactoryAxios } from "@feed-factory/feed-factory.helpers";
import { ExternalEventItemModel } from "@feed-factory/feed-factory.models.ndtrc/dist/Ndtrc";
import { EventModel } from "@/models/ItemModel";
import { ExtUrlDetails } from "@feed-factory/feed-factory.models.ndtrc";
import { WidgetEntityType } from "@/models/WidgetEntityType";
import { widgetConfig } from "../config/widgetConfig";
import { addressForEvent } from "../helpers/addressBuilder";
import { ExtendedExternalEventItemModel, ExtendedItemType } from "@/models/ItemModel";
import slugify from "slugify";
import { getEventCatIdByCategory, findEventCategory } from "./common";
import { getClosestDate } from "../helpers/dateHelper";

const getTitleInLang = (
  event: ExternalEventItemModel,
  lang: string | null
): string => {
  const langItem = Array.isArray(event.trcItemDetails) ? event.trcItemDetails.find((item) => item.lang === lang) : null;
  if (langItem) return langItem.title;
  // If specified language not found, return primary language of item
  return event.trcItemDetails && event.trcItemDetails.length > 0 ? event.trcItemDetails[0].title : "";
};


export const retrieveEvents = async (
  filterOnType: WidgetEntityType = "event",
  t: any, // translation function
  lang: string | null = "nl",
  city?: string,
  category?: string
): Promise<EventModel[]> => {
  let queryParams = {};

  const baseQueryParams = widgetConfig.baseParams.event;

  queryParams = baseQueryParams

  if (city) {
    queryParams = { ...queryParams, city: city };
  }

  if (category) {
    const categorySplit = category.split(",");
    if (categorySplit.length > 1) {
      const categoryIDs = categorySplit.map((cat) => getEventCatIdByCategory(cat));
      queryParams = { ...queryParams, types: categoryIDs.join(",") };
    } else {
      queryParams = { ...queryParams, types: getEventCatIdByCategory(category) };
    }
  }

  // Get additional filter params based on type

  const events = await feedFactoryAxios.get("events", {
    params: queryParams,
  });

  if (!events?.data?.results) {
    return Promise.reject("Could not retrieve events data...");
  }

  if (events.data.results.length === 0) {
    alert("No events found");
    return Promise.resolve([]);
  }

  const parsedEvents: EventModel[] = parseEvents(
    events.data.results,
    filterOnType,
    t,
    lang
  );


  return Promise.resolve(parsedEvents);
};

const parseEvents = (
  events: ExtendedExternalEventItemModel[],
  filterOnType: WidgetEntityType,
  t: any, // translation function
  lang: string | null
): EventModel[] => {

  let filteredEvents = events.filter((event) => {
    const hasDates =
      (event?.calendar?.singleDates?.length ?? 0) > 0 || (event?.calendar?.patternDates?.length ?? 0) > 0
    const hasImage = event.files && event.files.length > 0;
    const hasTitle = event.trcItemDetails && event.trcItemDetails.length > 0;
    return hasTitle && hasImage && hasDates;
  });

  let parsedEvents = filteredEvents.map((event) => {
    let mainImage = event.files?.[0];
    let mainImages = event.files?.filter((file) => file.main) ?? [];
    if (mainImages && mainImages.length > 0) {
      mainImage = mainImages[0];
    }
    const title = getTitleInLang(event, lang);

    let categoryString = '';

    const eventCategory = event.trcItemCategories.types[0] as ExtendedItemType;


    if (eventCategory && eventCategory.catid) {
      categoryString = findEventCategory(eventCategory.catid);
    }

    // let categoryString = category?.categorization.toLowerCase();


    const closestDate = getClosestDate(event);

    let eventFormattedDate: string | null | undefined = '';
    let eventRawStartDate: string | null | undefined = '';
    let eventRawEndDate: string | null | undefined = '';

    if (closestDate) {
      eventRawStartDate = closestDate.toISOString();
      // If it's a pattern date, set the end date
      const patternDate = event.calendar?.patternDates?.find(d => new Date(d.startdate) <= closestDate && new Date(d.enddate) >= closestDate);
      if (patternDate) {
        eventRawEndDate = patternDate.enddate;
      }
    }

    const dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const eventFormattedStartDate: string =
      eventRawStartDate
        ? lang === "nl"
          ? new Date(eventRawStartDate).toLocaleDateString("nl-NL", dateOptions)
          : lang === "en"
            ? new Date(eventRawStartDate).toLocaleDateString("en-GB", dateOptions)
            : new Date(eventRawStartDate).toLocaleDateString(
              "de-DE",
              dateOptions
            )
        : "";

    eventFormattedDate = eventFormattedStartDate;

    let eventFormattedEndDate: string | null | undefined = '';
    if (eventRawEndDate) {
      eventFormattedEndDate = new Date(eventRawEndDate).toLocaleDateString(
        "nl-NL",
        dateOptions
      );

      eventFormattedDate += " - " + eventFormattedEndDate;
    }

    // Set default urls in case none in item

    // Remove & character from title
    slugify.extend({ '&': '' })
    let visitUrl = `${t("veluwe:callToAction.event.url")}/${slugify(title, { lower: true })}`;

    // Fill with language-specific URL
    if (
      event.contactinfo !== undefined &&
      event.contactinfo.urls !== undefined &&
      event.contactinfo.urls.length > 0
    ) {
      let myUrl: ExtUrlDetails | undefined = event.contactinfo.urls.find(
        (url) => url.targetLanguage === lang
      );
      if (myUrl) {
        visitUrl = myUrl.url;
      } else {
        visitUrl = event.contactinfo.urls[0].url;
      }
    }


    return {
      id: event.id as string,
      title: title,
      date: eventFormattedDate,
      type: filterOnType,
      address: addressForEvent(event.location.address),
      category: categoryString,
      url: visitUrl + "?utm_source=partner&utm_medium=widget&utm_id=veluwe&utm_content=event",
      image: {
        url: mainImage?.hlink || "", // Use empty string as default value if mainImage?.hlink is undefined
        alt: title,
      },
    };
  });

  return parsedEvents;
};