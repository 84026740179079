import React from 'react';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
// import 'swiper/css/effect-cards';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/navigation';
// import 'swiper/css/scrollbar';
import {
	EventModel,
	ItemModel,
	RouteModel,
	LocationModel,
} from '@/models/ItemModel';
import NoEventsFound from './NoEventsFound';
import EventAsImage from './veluwe/Events/EventAsImage';
import LocationAsImage from './veluwe/Locations/LocationAsImage';
import RouteAsImage from './veluwe/Routes/RouteAsImage';
import './Swiper.scss';
// import { EffectCards } from "swiper";

const ItemsSwiper = (props: {
	items: ItemModel[];
	slidesPerView: number;
	className?: string;
}) => {
	let initialSlide = 1;
	if (props.items.length > 1) {
		initialSlide = 1;
	}
	return (
		<div className={'h-full ' + props?.className}>
			{props.items?.length > 0 && (
				<Swiper
					// effect={"coverflow"}
					// grabCursor={true}
					centeredSlides={true}
					modules={[Pagination, Autoplay]} //, EffectCoverflow]} //, Navigation,  Scrollbar, A11y]}
					spaceBetween={25}
					loop={true}
					slidesPerView={props.slidesPerView}
					pagination={{ clickable: true }}
					initialSlide={initialSlide}
					// scrollbar={{draggable: true}}
					// onSwiper={(swiper) => console.log(swiper)}
					// onSlideChange={() => console.log('slide change')}
					autoplay={{
						delay: 3000,
						pauseOnMouseEnter: true,
						disableOnInteraction: false,
					}}
					className='h-full'>
					{props?.items.map((item) => (
						<SwiperSlide key={item.id}>
							{item.type === 'event' && (
								<EventAsImage event={item as EventModel} />
							)}
							{item.type === 'location' && (
								<LocationAsImage location={item as LocationModel} />
							)}
							{item.type === 'route' && (
								<RouteAsImage route={item as RouteModel} />
							)}
						</SwiperSlide>
					))}
				</Swiper>
			)}
			{props.items?.length === 0 && <NoEventsFound />}
		</div>
	);
};

export default ItemsSwiper;
