import { Address } from '@feed-factory/feed-factory.models.ndtrc';

export const addressForLocation = (location: Address): string => {
	if (!location) return ""
	// Extract address parts from location
	const { street, housenr, city } = extractAddressParts(location);

	// combine street and housenr into one part, ensuring both are valid and not null/empty
	const streetAndHousenr = [street, housenr].filter(part => part && part.trim() !== '').join(' ');

	// Combine address parts into a single string, separated by a comma
	return [streetAndHousenr, city].filter(part => part && part.trim() !== '').join(', ');
}

export const addressForEvent = (location: Address): string => {
	if (!location) return ""
	return extractAddressParts(location).city;
}

export const addressForRoute = (location: Address): string => {
	if (!location) return ""
	return extractAddressParts(location).city;
}

const extractAddressParts = (location: Address): {
	street: string;
	housenr: string;
	city: string;
	zipcode: string;
	country: string;

} => {
	try {
		const { housenr, street, city, zipcode, country } = location;
		return {
			street: street ? street : '',
			housenr: housenr ? housenr : '',
			city: city ? city : '',
			zipcode: zipcode ? zipcode : '',
			country: country ? country : ''
		};
	} catch (error) {
		console.error('Error extracting address parts', error);
	}
}