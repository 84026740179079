import { ExtendedExternalEventItemModel } from "@/models/ItemModel";

export const getClosestDate = (event: ExtendedExternalEventItemModel): Date | null => {
  const now = new Date();
  let closestDate: Date | null = null;
  let smallestDifference = Infinity;

  // Check singleDates
  event.calendar?.singleDates?.forEach(dateObj => {
    const eventDate = new Date(dateObj.date);
    if (eventDate >= now) {
      const difference = eventDate.getTime() - now.getTime();
      if (difference < smallestDifference) {
        smallestDifference = difference;
        closestDate = eventDate;
      }
    }
  });

  // Check patternDates
  event.calendar?.patternDates?.forEach(dateObj => {
    const startDate = new Date(dateObj.startdate);
    const endDate = new Date(dateObj.enddate);

    if (endDate >= now) {
      const relevantDate = startDate > now ? startDate : now;
      const difference = relevantDate.getTime() - now.getTime();
      if (difference < smallestDifference) {
        smallestDifference = difference;
        closestDate = relevantDate;
      }
    }
  });

  return closestDate;
};