import { Route, Routes } from 'react-router-dom';
import { Widget } from './components/Widget';
import translationService from '@feed-factory/feed-factory.translation';
import { WidgetEntityType } from './models/WidgetEntityType';
import { useQueryParams } from './helpers/useQueryParams';
import { widgetConfig } from './config/widgetConfig';
import { getRGBColor } from './helpers/colorHelper';
import { WidgetEnums } from './models/WidgetEntityType';

function App() {
  const queryParams = useQueryParams();
  // Check if correct parameter passed
  const parseWidgetEntityType = (param: string): WidgetEntityType => {
    if (!param) {
      return 'event';
    }
    if (WidgetEnums[param as WidgetEntityType]) {
      return param as WidgetEntityType; // type assertion satisfies compiler
    } else {
      alert(
        'Please use a valid widget type: ' + Object.keys(WidgetEnums).join(', ')
      );
      return WidgetEnums.event;
    }
  };

  const parseWidgetLanguage = (param: string): string => {
    if (!param) {
      return 'nl';
    }

    const languages = ['nl', 'en', 'de']; // , "de", "fr", "it", "es", "pt", "ja", "zh"
    if (typeof param === 'string' && languages.includes(param)) {
      return param;
    } else {
      alert('Please use a valid language: ' + languages.join(', '));
      return 'nl';
    }
  };

  const widgetEntityType = parseWidgetEntityType(queryParams.get('type'));
  const widgetLanguage = parseWidgetLanguage(queryParams.get('lang'));
  const widgetEntityCity = queryParams.get('city') || '';
  const widgetEntityCategory = queryParams.get('category') || '';

  // Load translations
  // i18n.use(initReactI18next);
  widgetConfig.translations.forEach((t: any) => {
    translationService.addResourceBundle(t.lang, 'veluwe', t.file);
  });
  translationService.changeLanguage(widgetLanguage);

  // Set theme as css variable
  const theme = widgetConfig.theme;
  document.documentElement.style.setProperty(
    '--color-primary-rgb',
    getRGBColor(theme.primaryColor)
  );
  document.documentElement.style.setProperty(
    '--color-secondary-rgb',
    getRGBColor(theme.secondaryColor)
  );

  if (widgetEntityType)
    return (
      <Routes>
        <Route
          path='/'
          element={
            <Widget
              filterOnType={widgetEntityType}
              lang={widgetLanguage}
              city={widgetEntityCity}
              category={widgetEntityCategory}
            />
          }
        />
      </Routes>
    );
}

export default App;
