import { feedFactoryAxios } from "@feed-factory/feed-factory.helpers";
import { ExternalLocationItemModel } from "@feed-factory/feed-factory.models.ndtrc/dist/Ndtrc";
import { LocationModel } from "@/models/ItemModel";
import { ExtUrlDetails } from "@feed-factory/feed-factory.models.ndtrc";
import { WidgetEntityType } from "@/models/WidgetEntityType";
import { widgetConfig } from "../config/widgetConfig";
import { addressForLocation } from "../helpers/addressBuilder";
import { ExtendedExternalLocationItemModel } from "@/models/ItemModel";
import { getLocationCatIdByCategory, findLocationCategory } from "./common";
import slugify from "slugify";

const getTitleInLang = (
	location: ExternalLocationItemModel,
	lang: string | null
): string => {
	const langItem = Array.isArray(location.trcItemDetails) ? location.trcItemDetails.find((item) => item.lang === lang) : null;
	if (langItem) return langItem.title;
	// If specified language not found, return primary language of item
	return location.trcItemDetails && location.trcItemDetails.length > 0 ? location.trcItemDetails[0].title : "";
};

export const retrieveLocations = async (
	filterOnType: WidgetEntityType = "location",
	t: any, // translation function
	lang: string | null = "nl",
	city?: string,
	category?: string
): Promise<LocationModel[]> => {
	const baseQueryParams = widgetConfig.baseParams.location;

	let queryParams = {};

	queryParams = baseQueryParams;
	// Get additional filter params based on type
	if (city) {
		queryParams = { ...queryParams, city: city };
	}

	if (category) {
		const categorySplit = category.split(",");
		if (categorySplit.length > 1) {
			const categoryIDs = categorySplit.map((cat) => getLocationCatIdByCategory(cat));
			queryParams = { ...queryParams, types: categoryIDs.join(",") };
		} else {
			queryParams = { ...queryParams, types: getLocationCatIdByCategory(category) };
		}
	}

	const locations = await feedFactoryAxios.get("locations", {
		params: queryParams,
	});


	if (!locations?.data?.results) {
		return Promise.reject("Could not retrieve locations data...");
	}

	if (locations.data.results.length === 0) {
		alert("No locations found");
		return Promise.resolve([]);
	}


	const parsedLocations: LocationModel[] = parseLocation(
		locations.data.results,
		filterOnType,
		t,
		lang
	);
	return Promise.resolve(parsedLocations);
};

const isValidImageFile = (fileName: string): boolean => {
	const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
	const extension = fileName.split('.').pop()?.toLowerCase();
	return validExtensions.includes(extension || '');
};

const parseLocation = (
	locations: ExtendedExternalLocationItemModel[] | any[],
	filterOnType: WidgetEntityType,
	t: any, // translation function
	lang: string | null
): LocationModel[] => {

	let filteredLocations = locations.filter((location) => {

		const hasImage = location.files && location.files.length > 0;
		const hasTitle = location.trcItemDetails && location.trcItemDetails.length > 0;
		return hasTitle && hasImage;
	});

	let parsedLocations = filteredLocations.map((location) => {
		let mainImage = location.files?.find((file: { hlink: string; }) => isValidImageFile(file.hlink));

		let mainImages = location.files?.filter((file: { main: any; hlink: string; }) => file.main && isValidImageFile(file.hlink)) ?? [];
		if (mainImages && mainImages.length > 0) {
			mainImage = mainImages[0];
		}
		const title = getTitleInLang(location, lang);

		// Set default urls in case none in item
		slugify.extend({ '&': '' })
		let visitUrl = `${t("veluwe:callToAction.location.url")}/${slugify(title, { lower: true })}`;

		// Fill with language-specific URL
		if (
			location.contactinfo !== undefined &&
			location.contactinfo.urls !== undefined &&
			location.contactinfo.urls.length > 0
		) {
			let myUrl: ExtUrlDetails | undefined = location.contactinfo.urls.find(
				(url: any) => url.urlServiceType === "dmo"
			);
			if (myUrl) {
				visitUrl = myUrl.url;
			} else {
				visitUrl = location.contactinfo.urls[0].url
			}
		}

		// find location category in specified language
		// let locationCategory = location.trcItemCategories?.types?.[0]?.categoryTranslations?.[0].label || "";

		let locationCategory = findLocationCategory(location.trcItemCategories.types[0].catid);


		return {
			id: location.id as string,
			title: title,
			type: filterOnType,
			address: addressForLocation(location.location.address),
			category: locationCategory,
			url: visitUrl + "?utm_source=partner&utm_medium=widget&utm_id=veluwe&utm_content=location",
			image: {
				url: mainImage?.hlink || "", // Use empty string as default value if mainImage?.hlink is undefined
				alt: title,
			},
		};
	});

	return parsedLocations;
};

